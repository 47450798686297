	.Dropzone {
	  height: 200px;
	  background-color: #fff;
	  border: 2px dashed rgb(187, 186, 186);
	  border-radius: 10px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  flex-direction: column;
	  font-size: 16px;
	  padding: 30px;
	  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);

	}
	.Icon {
	  opacity: 0.4;
	  height: 64px;
	  width: 64px;
	}
	.FileInput {
	  display: none;
	}
	.Highlight {
	  background-color: rgb(188, 185, 236);
	}